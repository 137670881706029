<template>
  <div>
    <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar" />

    <!-- <Header @toggleSidebar="toggleSidebar" /> -->
    <!--====== POST LAYOUT 1 PART START ======-->
    <section class="post-layout-1-area post-layout-3-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="about-author-content">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item" ><router-link :to="{ name: 'Home'}">Inicio</router-link></li>
                  <li class="breadcrumb-item"><router-link :to="{ name: 'Archive'}">Noticias </router-link> </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{articulo.titulo}}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="post-layout-top-content post-layout-top-content-3">
              <!-- <div
                class="
                  post-categories
                  d-flex
                  justify-content-between
                  align-content-center
                "
              >
                <div class="categories-share">
                  <ul>
                    <li><i class="fas fa-comment"></i>45020</li>
                    <li><i class="fas fa-fire"></i>45020</li>
                    <li>6 minutes read</li>
                  </ul>
                </div>
              </div> -->
              <div v-if="articulo.creador" class="post-content">
                <h3 class="title">
                    {{articulo.titulo}}
                </h3>
                <div class="post-author d-flex justify-content-between">
                  <!-- <div class="author-info"> -->
                    <!-- <div class="thumb">
                      <img :src="articulo.creador.avatarURL" alt="" />
                    </div> -->
                    <div class="d-flex justify-content-center">
                        <div style="font-weight: bold;">{{articulo.creador.username}} </div>
                        <div class="ml-2"> {{formatDate(articulo.fecha)}} </div> 
                    </div>

                   
                  <!-- </div> -->
                  <div class="author-social">
                    <ul>
                      <li>
                        <a :href="configs.facebook_url"><i class="fab fa-facebook-f"></i></a>
                      </li>
                      <li>
                        <a :href="configs.instagram_url" ><i class="fab fa-twitter"></i></a>
                      </li>
                      <li>
                        <a :href="configs.twitter_url"><i class="fab fa-instagram"></i></a>
                      </li>
                    
                      <!-- <li>
                        <a href="#"><i class="fas fa-ellipsis-v"></i></a>
                      </li> -->
                    </ul>
                  </div>
                </div>
                <div class="thumb">
                  <img :src="articulo.imagenURLLg" alt="" />
                </div>

                <div class="mt-4">
                    <anuncios-banner :tipo-anuncio="'w4'"/>
                </div>
            
              </div>

              <div class="post-quote post-quote-3-style text-center mt-15">
                <div class="post-quote-content">
                  <p>
                   {{ articulo.subtitulo }}
                  </p>
                </div>
              </div>

              <div class="post-text mt-30 mb-30">
                <div
                    class="text-justify"
                    v-html="articulo.contenido"
                />

                <div v-if="articulo.notasRelacionadas" class="mt-4 mb-4">
                    <h6 style="font-weight: 400;">Notas relacionadas:</h6>
                    <div v-for="nota in articulo.notas_relacionadas" :key="nota.id">
                        <router-link :to="{ name: 'PostThree', params: { slug: nota.slug} }">
                            <h6 class="m-0 p-0 nota_relacionada" style="font-style: italic;"> {{nota.titulo}}</h6>
                        </router-link>
                    </div>
                </div>

                <div v-if="articulo.categorias" class="post-tags">
                    <ul>
                        <li>
                            <a><i class=""></i> Categorías:</a>
                        </li>

                        <li class="categoria-item" v-for="categoria in articulo.categorias"  :key="categoria.id">
                            <a :href="'/categoria/'+categoria.slug" >{{categoria.titulo}}</a>
                        </li>
                       
                    </ul>
                </div>
              </div>

              
            </div>

            <div class="trending-sidebar-slider position-relative mr-4 mt-15">
                <router-link v-if="articulo.anterior" :to="{ name: 'PostThree', params: { slug: articulo.anterior.slug} }">
                    <span
                        class="prev slick-arrow"
                        style="display: block"
                        ><i class="fal fa-angle-left text-white font-weight-bold"></i
                    ></span>
                </router-link>
                
                <router-link v-if="articulo.siguiente" :to="{ name: 'PostThree', params: { slug: articulo.siguiente.slug} }">
                    <span
                        class="next slick-arrow"
                        style="display: block"
                        ><i class="fal fa-angle-right text-white font-weight-bold"></i
                    ></span>
                </router-link>
            </div>
          </div>

       

          

          <div class="col-lg-4 mt-4">
            <style-one  v-if="categoriasDestacadas.length" :datas="posts" :categorias-destacadas="categoriasDestacadas" :trendingShortPost="false" :trendingBigPost="false"  :sharePost="true" :categoria-destacada-tres="categoriaDestacadaTres" :estadisticas="true"  :articulos-vistos="articulosVistos"  :configs="configs"/>
          </div>

        </div>
      </div>
    </section>

    <!--====== POST LAYOUT 1 PART ENDS ======-->

    <!--====== LATEST NEWS PART START ======-->
    <our-latest-news :titulo="'Otros Artículos'" stype="col" :posts = "otrosArticulos"/>

    <!--====== LATEST NEWS PART ENDS ======-->

    <!--====== ADD PART ENDS ======-->

    <!--====== GO TO TOP PART START ======-->
    <div class="go-top-area">
      <div class="go-top-wrap">
        <div class="go-top-btn-wrap">
          <a href="#" class="go-top go-top-btn d-block">
            <i class="fa fa-angle-double-up"></i>
            <i class="fa fa-angle-double-up"></i>
          </a>
        </div>
      </div>
    </div>
    <!--====== GO TO TOP PART ENDS ======-->
  </div>
</template>

<script>
// import Header from "../../Utility/Header/index";
import StyleOne from "../../Utility/Sidebar/StyleOne.vue";
import Posts from "../../Data/NewsRowCard";
import OurLatestNews from "../../Utility/Common/OurLatestNews.vue";
import Drawer from "../../Mobile/Drawer.vue";


import { http } from "../../../axios"
import moment from 'moment';
import AnunciosBanner from "../../../components/Utility/Anuncios/AnunciosBanner.vue";



export default {
  components: {StyleOne, OurLatestNews, Drawer, AnunciosBanner },
  data: () => ({
    sidebar: false,
    configs: {},
    articulo: {},
    categoriasDestacadas: [],
    otrosArticulos: [],
    posts: Posts.data,
    categoriaDestacadaTres: {},

    articulosVistos: [],
 


  }),

  metaInfo() {
    return {
      meta: [
          // Twitter Card
        //   {name: 'twitter:card', content: 'summary'},
        //   {name: 'twitter:title', content: 'Vue Social Cards Example'},
        //   {name: 'twitter:description', content: 'Vue sample site showing off Twitter and Facebook Cards.'},
        //   // image must be an absolute path
        //   {name: 'twitter:image', content: this.logo},
          // Facebook OpenGraph
          {property: 'og:title', content: this.articulo.titulo },
          {property: 'og:site_name', content: 'https://laveci.com'},
          {property: 'og:type', content: 'website'},
          {property: 'og:image', content:  this.articulo.imagenURLLg},
          {property: 'og:description', content: this.articulo.subtitulo }
      ]
    }
  },

  created() {
    document.addEventListener("scroll", this.topToBottom);
    this.fetchConfigs();
    this.fetchArticulo();
    this.fetchCategoriasDestacadas();
    this.fetchOtrosArticulos();
    this.fetchCategoriaDestacadaTres();

    this.fetchArticulosMasVistos();



  },

  watch: {
    '$route.params.slug': {
        handler: function() {
            this.fetchArticulo();
        },
        // deep: true,
        // immediate: true
    }

  },

  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },
    fetchConfigs(){
        http
        .get("web/configs",{
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then( response => {
            this.configs = response.data.items;
        })
        .catch((error) => {
            console.log(error);
            this.errorMessage = error
            this.loadingRecintos = false
        });
    },

    fetchArticulo(data){
        http
        .get("web/articulo",{
            params:{ slug: this.$route.params.slug, accion: data},
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then( response => {
            this.articulo = response.data.data;
        })
        .catch((error) => {
            console.log(error);
            this.errorMessage = error
            this.loadingRecintos = false
        });
    },

    
    fetchCategoriasDestacadas(){
          http
          .get("web/categorias-destacadas",{
              params:{ slug: this.$route.params.slug},
              headers: {
                  'Content-Type': 'application/json',
              }
          })
          .then( response => {
              this.categoriasDestacadas = response.data.items;
          })
          .catch((error) => {
              console.log(error);
              this.errorMessage = error
              this.loadingRecintos = false
          });
      },

      fetchOtrosArticulos(){
        http
        .get("web/otros-articulos",{
            params:{ slug: this.$route.params.slug},
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then( response => {
            this.otrosArticulos = response.data.items;
        })
        .catch((error) => {
            console.log(error);
            this.errorMessage = error
            this.loadingRecintos = false
        });
    },
    fetchCategoriaDestacadaTres(){
        http
        .get("web/categoria-destacada-tres",{
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then( response => {
            this.categoriaDestacadaTres = response.data.items;
        })
        .catch((error) => {
            console.log(error);
            this.errorMessage = error
            this.loadingRecintos = false
        });
    },

    fetchArticulosMasVistos(){
          http
          .get("web/articulos-mas-vistos",{
              headers: {
                  'Content-Type': 'application/json',
              }
          })
          .then( response => {
              this.articulosVistos = response.data.items;
          })
          .catch((error) => {
              console.log(error);
              this.errorMessage = error
              this.loadingRecintos = false
          });
      },

     


    // nextBeforeArticulo(accion){
    //     http
    //     .get("web/articulo",{
    //         params:{},
    //         headers: {
    //             'Content-Type': 'application/json',
    //         }
    //     })
    //     .then( response => {
    //         this.articulo = response.data.data;
    //         // location.reload();
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //         this.errorMessage = error
    //         this.loadingRecintos = false
    //     });
    // },
   

  },

  setup() {
    const formatDate = (item) =>{
        moment.locale('es')
        let date = moment(item).format("MMMM D, YYYY");
        return date
	}

    return {
      formatDate,

    }
  }

};
</script>

<style></style>
