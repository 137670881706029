<template>
  <div
    v-if="stype === 'small'"
    class="feature-post d-block"
  >
    <div class="feature-post-thumb">
        <router-link :to="{ name: 'PostThree', params: { slug: datas.slug} }">
          <img v-if="datas.imagenURLMd1"
            :src="datas.imagenURLMd1"
            alt="feature" 
          />
        </router-link>
    </div>
    <div class="feature-post-content">
      <div class="post-meta">
        <div v-if="datas.firstCategoria" class="meta-categories">
            <router-link :to="{ name: 'Features', params: { slug: datas.firstCategoria.slug} }">
                {{ datas.firstCategoria.titulo}}
            </router-link>
        </div>
        <div class="meta-date">
          <span>{{ formatDate(datas.fecha) }}</span>
        </div>
      </div>
      <h4 class="title">
        <router-link :to="{ name: 'PostThree', params: { slug: datas.slug} }">
            {{ datas.titulo }}
        </router-link>
      </h4>
    </div>
  </div>
  <!-- <router-link
    to="/posts/postOne"
    v-else-if="stype === 'big'"
    class="single-play-post-item d-block"
  >
    <img :src="require(`@/assets/images/` + datas.image)" alt="feature" />
    <div class="single-play-post-content">
      <div class="post-meta">
        <div class="meta-categories">
          <a href="#">{{ datas.category.name }}</a>
        </div>
        <div class="meta-date">
          <span>{{ datas.category.create_At }}</span>
        </div>
      </div>
      <h3 class="title">
        <a href="#">{{ datas.title }}</a>
      </h3>
    </div>
    <div v-if="datas.action === 'play'" class="play-btn">
      <a class="video-popup" @click.prevent="$store.dispatch('toggleVideo')"
        ><i class="fas fa-play"></i
      ></a>
    </div>
    <div v-if="datas.action === 'trending'" class="trending-btn">
      <a href="#"><i class="fas fa-bolt"></i></a>
    </div>
  </router-link> -->
</template>

<script>
import moment from 'moment';

export default {
  props: {
    datas: {
      type: Object,
    },
    stype: {
      type: String,
    },
  },

  setup() {
    const formatDate = (item) =>{
        moment.locale('es')
        let date = moment(item).format("MMMM D, YYYY");
        return date
	}
    return {
      formatDate,

    }
  }

};
</script>

<style></style>
