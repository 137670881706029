import Vue from 'vue'

import Meta from 'vue-meta'
Vue.use(Meta);

import App from './App.vue'
import router from './router'
import '../src/assets/css/bootstrap.min.css'
import '../src/assets/css/font-awesome.min.css'
import '../src/assets/css/magnific-popup.css'
import '../src/assets/css/default.css'
import '../src/assets/css/style.css'
import '../src/assets/css/custom.css'
import {store} from './store/index'



Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')


// created(){
//   // this.preloader = true;
//   console.log('created');
// },
// mounted(){
//   let preloader = document.querySelector('.preloader');
//   preloader.classList.add('ocultar_preloader');
//   console.log('mounted', {preloader});
// },