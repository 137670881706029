<template>
  <div>
    <Main />
    <other 
        v-if="totalArticulos && paginaActual && totalPaginas" 
        :total-articulos="totalArticulos" 
        :pagina-actual="paginaActual" 
        :total-paginas="totalPaginas" 
        :articulos="articulos"
        @fetch-all-articulos="fetchAllArticulos"

        :categorias-destacadas="categoriasDestacadas"
        :categoria-destacada-tres="categoriaDestacadaTres"
        :articulos-vistos="articulosVistos"
        :configs="configs" 
    />
    <!-- <div class="add-area text-center">
      <a href="#">
        <img src="@/assets/images/ads/one_ad.png" alt="" />
      </a>
    </div> -->
  </div>
</template>

<script>
import Main from "./Main.vue";
import Other from "./Other.vue";

import { http } from "../../axios"

export default {
  components: {
    Main,
    Other,
  },
  data: () => ({
    configs: {},
    articulos: [],
    totalArticulos: null,
    paginaActual: null,
    totalPaginas: null,
    categoriasDestacadas: [],
    categoriaDestacadaTres: {},
    articulosVistos: [],


  }),
  created() {
    document.addEventListener("scroll", this.topToBottom);
    this.fetchConfigs();
    this.fetchAllArticulos();
    this.fetchCategoriasDestacadas();
    this.fetchCategoriaDestacadaTres();
    this.fetchArticulosMasVistos();



  },
  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },

    fetchConfigs(){
      http
      .get("web/configs",{
          headers: {
              'Content-Type': 'application/json',
          }
      })
      .then( response => {
          this.configs = response.data.items;
      })
      .catch((error) => {
          console.log(error);
          this.errorMessage = error
          this.loadingRecintos = false
      });
    },

    fetchAllArticulos(pagina){
        http
        .get("web/all-articulos",{
            params:{ page: pagina },
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then( response => {
            this.articulos = response.data.items;
            this.totalArticulos = response.data.total;
            this.paginaActual = response.data.current_page;
            this.totalPaginas = response.data.last_page;
        })
        .catch((error) => {
            console.log(error);
            this.errorMessage = error
            this.loadingRecintos = false
        });
    },

    fetchCategoriasDestacadas(){
          http
          .get("web/categorias-destacadas",{
              headers: {
                  'Content-Type': 'application/json',
              }
          })
          .then( response => {
              this.categoriasDestacadas = response.data.items;
          })
          .catch((error) => {
              console.log(error);
              this.errorMessage = error
              this.loadingRecintos = false
          });
      },

      
      fetchCategoriaDestacadaTres(){
          http
          .get("web/categoria-destacada-tres",{
              headers: {
                  'Content-Type': 'application/json',
              }
          })
          .then( response => {
              this.categoriaDestacadaTres = response.data.items;
          })
          .catch((error) => {
              console.log(error);
              this.errorMessage = error
              this.loadingRecintos = false
          });
      },

      fetchArticulosMasVistos(){
          http
          .get("web/articulos-mas-vistos",{
              headers: {
                  'Content-Type': 'application/json',
              }
          })
          .then( response => {
              this.articulosVistos = response.data.items;
              console.log("a",this.articulosVistos)
          })
          .catch((error) => {
              console.log(error);
              this.errorMessage = error
              this.loadingRecintos = false
          });
      },



  },
};
</script>

<style></style>
