<template>
  <div class="trending-social mt-40">
    <div class="section-title">
      <h3 class="title title seccion-siganos">Síganos</h3>
    </div>
    <ul>
      <li>
        <a :href="configs.facebook_url">
          <i class="fab fa-facebook-f"></i>
          <span>87000</span>
          <p>Seguidores</p>
        </a>
      </li>


      <li>
        <a class="item-4" :href="configs.instagram_url">
          <i class="fab fa-instagram"></i>
          <span>3190</span>
          <p>Seguidores</p>
        </a>
      </li>

      <li>
        <a class="item-2" :href="configs.twitter_url">
          <i class="fab fa-whatsapp"></i>
          <span>3456</span>
          <p>Suscritos</p>
        </a>
      </li>


    </ul>
  </div>
</template>

<script>
export default {
    props: {
        configs: {
            type: Object,
            default: () => {},
        },
    },

};


</script>

<style></style>
