<template>
  <category-layout>
    <section class="about-item-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="about-author-content pt-15">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item" ><router-link :to="{ name: 'Home'}">Inicio</router-link></li>
                  <li class="breadcrumb-item"><a href="#">Categoría</a></li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ categoria.titulo }}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="about-tab-btn mt-40">
              <div class="archive-btn">
                <ul>
                  <li>
                    <span class="seccion-siganos">Categoría: <span>{{ categoria.titulo }}</span></span>
                  </li>
                </ul>
              </div>
              <div class="about-post-items">
                <div class="row">
                  <div v-for="(data,index) in articulos" :key="index" class="col-lg-6 col-md-6">
                    <div class="trending-image-post feature-item mt-30">
                      <router-link :to="{ name: 'PostThree', params: { slug: data.slug} }">
                        <img :src="data.imagenURLMd3" alt="trending">
                      </router-link>
                      <div class="trending-image-content">
                        <div class="post-date text-white">
                          <span>{{formatDate(data.fecha) }}</span>
                        </div>
                        <h3 class="title ">
                          <router-link :to="{ name: 'PostThree', params: { slug: data.slug} }">
                            {{ data.titulo }}
                          </router-link>
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="pagination-item pt-40">
                      <nav aria-label="Page navigation example">
                        <ul class="pagination">
                          
                            <li v-for="pagina in totalPaginas" :key=pagina class="page-item"  :class="paginaActual == pagina ? 'active' : ''" ><a @click="paginado(pagina)" class="page-link"> {{ pagina }}</a></li>
                          
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <style-one :sharePost="true" :trendingBigPost="false"  :trendingShortPost="true" :datas="ultimosArticulos" :articulos-vistos="articulosVistos" :configs="configs"  />
          </div>
        </div>
      </div>
    </section>
  </category-layout>
</template>

<script>
import StyleOne from "../Utility/Sidebar/StyleOne.vue";
import CategoryLayout from "./CategoryLayout.vue";
import Posts from "../Data/TrendingHomeThree";
import { http } from "../../axios"
import moment from 'moment';

export default {
  components: { CategoryLayout, StyleOne },
  data: () => ({
    posts: Posts.data,
    categoria: {},
    ultimosArticulos: [],

    articulos: [],
    totalArticulos: null,
    paginaActual: null,
    totalPaginas: null,

    articulosVistos: [],
    configs: {},


  }),
  created() {
    this.fetchCategoria();
    this.fetchUltimosArticulos();

    this.fetchArticulosMasVistos();
    this.fetchConfigs();


  },
  methods: {
    fetchCategoria(pagina){
        http
        .get("web/categoria",{
            params:{ slug: this.$route.params.slug, page: pagina },
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then( response => {
            this.categoria = response.data.data;

            this.articulos = response.data.items;
            this.totalArticulos = response.data.total;
            this.paginaActual = response.data.current_page;
            this.totalPaginas = response.data.last_page;

        })
        .catch((error) => {
            console.log(error);
            this.errorMessage = error
            this.loadingRecintos = false
        });
    },

    fetchUltimosArticulos(){
          http
          .get("web/ultimos-articulos",{
              params:{ categoria: this.$route.params.slug},
              headers: {
                  'Content-Type': 'application/json',
              }
          })
          .then( response => {
              this.ultimosArticulos = response.data.items;
          })
          .catch((error) => {
              console.log(error);
              this.errorMessage = error
              this.loadingRecintos = false
          });
      },

      paginado (pagina) {
      if(pagina != this.paginaActual ){
        this.fetchCategoria(pagina);
      }
    },

    fetchArticulosMasVistos(){
          http
          .get("web/articulos-mas-vistos",{
              headers: {
                  'Content-Type': 'application/json',
              }
          })
          .then( response => {
              this.articulosVistos = response.data.items;
          })
          .catch((error) => {
              console.log(error);
              this.errorMessage = error
              this.loadingRecintos = false
          });
      },

      fetchConfigs(){
          http
          .get("web/configs",{
              headers: {
                  'Content-Type': 'application/json',
              }
          })
          .then( response => {
              this.configs = response.data.items;
          })
          .catch((error) => {
              console.log(error);
              this.errorMessage = error
              this.loadingRecintos = false
          });
      },


  },
  setup() {
    const formatDate = (item) =>{
			moment.locale('es')
			let date = moment(item).format("MMMM D, YYYY");
			return date
		}
    return {
      formatDate,

    }
  },

};
</script>

<style></style>
