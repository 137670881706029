<template>
  <section class="feature-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-title">
            <router-link :to="{ name: 'Archive' }">
                <h3 class="title seccion-ultimas-noticias">Noticias</h3>
            </router-link>

          
          </div>
        </div>
      </div>
      <div class="feature-post-slider position-relative">
        <span
          @click="featureSliderPrev"
          class="prev slick-arrow"
          style="display: block"
          ><i class="fal fa-angle-left"></i
        ></span>
        <slider :settings="featureSliderSettings" ref="featureSlider">
          <template v-for="(data, index) in ultimosArticulos">
            <div class="px-2" :key="index">
              <overlay-card stype="small" :datas="data" />
            </div>
          </template>
        </slider>
        <span
          @click="featureSliderNext"
          class="next slick-arrow"
          style="display: block"
          ><i class="fal fa-angle-right "></i
        ></span>
      </div>
    </div>
  </section>
</template>

<script>
import Slider from "../Helpers/Slider.vue";
import OverlayCard from "../Utility/Cards/OverlayCard.vue";
export default {
  components: { Slider, OverlayCard },
  props: {
    ultimosArticulos: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    //feature
    featureSliderSettings: {
      autoplay: true,
      slidesToShow: 4,
      slideToScroll: 1,
      arrows: false,
    
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slideToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slideToScroll: 1,
          },
        },
      ],
    },
  }),
  methods: {
    //feature
    featureSliderNext() {
      this.$refs.featureSlider.next();
    },
    featureSliderPrev() {
      this.$refs.featureSlider.prev();
    },
  },
};
</script>

<style></style>
