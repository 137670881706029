
<template>
	<div>
        
      <div id="app">

         <!--====== PRELOADER START ======-->
        <div v-if="loader" id="id_preloader" class="preloader">
          <div>
              <div class="nb-spinner"></div>
          </div>
        </div>
        <!--====== PRELOADER ENDS ======-->

        <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar" />

        <!--====== HEADER PART START ======-->
        <header-one image="logo.png" @toggleSidebar="toggleSidebar"/>
        <!--====== HEADER PART ENDS ======-->

        <router-view />

        <!--====== Anuncios START ======-->
        <anuncios-banner :tipo-anuncio="'w1'"/>
        <!--====== Anuncios ENDS ======-->
        
        
        <!--====== FOOTER PART START ======-->
        <footer-one/>
        <!--====== FOOTER PART ENDS ======-->

      

        <!--====== GO TO TOP PART START ======-->
        <div class="go-top-area">
          <div class="go-top-wrap">
            <div class="go-top-btn-wrap">
              <a href="#" class="go-top go-top-btn d-block">
                <i class="fa fa-angle-double-up"></i>
                <i class="fa fa-angle-double-up"></i>
              </a>
            </div>
          </div>
        </div>
        <!--====== GO TO TOP PART ENDS ======-->

     
    </div>
</div>
</template>
<style>
  .ocultar_preloader {
    display: none !important;
  }
</style>
<script>
import Drawer from "./components/Mobile/Drawer.vue";
import HeaderOne from "./components/Utility/Header/index.vue";

import FooterOne from "./components/Utility/Footer/FooterOne.vue";

import AnunciosBanner from "./components/Utility/Anuncios/AnunciosBanner.vue";

export default{
  components: {
    HeaderOne,
    FooterOne,
    Drawer,
    AnunciosBanner,
  },
  data: () => ({
    sidebar: false,
   
  }),

  methods:{
    toggleSidebar() {
        this.sidebar = !this.sidebar;
      },
  },
  
  computed: {
      loader: function(){
          return this.$store.state.loader;
      },
  },
}
</script>


