<template>
  <section class="trending-news-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div
            :class="[
              'section-title',
              darkClass && 'title' in darkClass ? darkClass.title : '',
            ]"
          >
            <router-link :to="{ name: 'Features', params: { slug: categoriaDestacadaUno.slug} }">
                <h3 class="title seccion-ultimas-categoria">{{categoriaDestacadaUno.titulo}}</h3>
            </router-link>
    
          </div>
          <div class="trending-news-slider position-relative">
            <span
              @click="trandingPrev"
              class="prev slick-arrow"
              style="display: block"
              ><i class="fal fa-angle-left"></i
            ></span>
            <slider v-if="categoriaDestacadaUno.articulos" :settings="trendingSettings" ref="trendingSlider">
              <template v-for="(data, index) in categoriaDestacadaUno.articulos">
                <div :key="index" class="px-2">
                  <divide-card
                    :class="[
                      darkClass && 'item' in darkClass ? darkClass.item : '',
                    ]"
                    stype="col"
                    :datas="data"
                  />
                </div>
              </template>
            </slider>
            
            <span
              @click="trandingNext"
              class="next slick-arrow"
              style="display: block"
              ><i class="fal fa-angle-right"></i
            ></span>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <div
                :class="[
                  'trending-news-post-items',
                  darkClass && 'news_section' in darkClass
                    ? darkClass.news_section
                    : '',
                ]"
              >
                <template
                  v-for="(small, index) in categoriaDestacadaDos.articulos"
                >
                  <row-card
                    :class="[
                      darkClass && 'news_item' in darkClass
                        ? darkClass.news_item
                        : '',
                    ]"
                    :key="index"
                    :trending="true"
                    :datas="small"
                  />
                </template>
              </div>
            </div>

 

            <div class="col-lg-6 col-md-6">
              <div
                :class="[
                  'trending-news-post-items',
                  darkClass && 'news_section' in darkClass
                    ? darkClass.news_section
                    : '',
                ]"
              >
                <template
                  v-for="(small, index) in categoriaDestacadaTres.articulos"
                >
                  <row-card
                    :class="[
                      darkClass && 'news_item' in darkClass
                        ? darkClass.news_item
                        : '',
                    ]"
                    :key="index"
                    :trending="true"
                    :datas="small"
                  />
                </template>
              </div>
            </div>
          </div>
        </div>
        

        <div class="col-lg-4">
          <div class="trending-right-sidebar">
            <style-one :ad=true :trendingShortPost=false :trendingBigPost=false :articulos-vistos="articulosVistos" :configs="configs"></style-one>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Slider from "../Helpers/Slider.vue";
import DivideCard from "../Utility/Cards/DivideCard.vue";
import TrendingPosts from "../Data/TrendingPost";
import smallPostGallery from "../Data/NewsRowCard";
import RowCard from "../Utility/Cards/RowCard.vue";
// import FollowCount from "../Utility/FollowCount/index";


import StyleOne from "../Utility/Sidebar/StyleOne.vue";

export default {
  components: { Slider, DivideCard, RowCard, StyleOne },
  props: {
    darkClass: {
      type: Object,
    },
    categoriaDestacadaUno: {
      type: Object,
      default: () => {},
    },
    categoriaDestacadaDos: {
      type: Object,
      default: () => {},
    },
    categoriaDestacadaTres: {
      type: Object,
      default: () => {},
    },

    articulosVistos: {
        type: Array,
        default: () => [],
    },

    configs: {
      type: Object,
      default: () => {},
    },

  },
  data: () => ({
    smallPostGallery: smallPostGallery.data,
    TrendingPosts: TrendingPosts.data,
    trendingSettings: {
      arrows: false,
      slidesToShow: 2,
      slideToScroll: 1,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slideToScroll: 1,
          },
        },
      ],
    },
    trendingSidebarSlide: {
      arrows: false,
      slidesToShow: 1,
      slideToScroll: 1,
    },
  }),
  methods: {
    //trending
    trandingPrev() {
      this.$refs.trendingSlider.prev();
    },
    trandingNext() {
      this.$refs.trendingSlider.next();
    },
    tssPrev() {
      this.$refs.trendingSidebarSlide.prev();
    },
    tssNext() {
      this.$refs.trendingSidebarSlide.next();
    },
  },
};
</script>

<style></style>
