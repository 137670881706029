<template>

  <!-- categorías-destacadas -->
  <div v-if="category" class="gallery_item">
    <template v-if="datas">
      <div class="gallery_item_thumb">
        <router-link :to="{ name: 'PostThree', params: { slug: datas.slug} }">
            <img
                v-if="datas.imagenURLSm"
                :src="datas.imagenURLSm"
                alt="post"
            />
        </router-link>
      </div> 
      <div class="gallery_item_content">
        <div class="post-meta">
          <div class="meta-categories">
            <router-link :to="{ name: 'Features', params: { slug: datas.firstCategoria.slug} }">
                {{ datas.firstCategoria.titulo }}
            </router-link>

          </div>
          <div class="meta-date">
            <span>{{formatDate(datas.fecha)}}</span>
          </div>
        </div>
        <h4 class="title">
            <router-link :to="{ name: 'PostThree', params: { slug: datas.slug} }">
                {{ datas.titulo }}
            </router-link>
        </h4>
      </div>
    </template>
</div>


  <!-- articulos de una sola categoría -->
  <div v-else-if="trending" class="gallery_item">
    <div class="gallery_item_thumb">
      <router-link :to="{ name: 'PostThree', params: { slug: datas.slug} }">
        <img
          v-if="datas.imagenURLSm"
          :src="datas.imagenURLSm"
          alt="gallery"
        />
      </router-link> 
      <div v-if="datas.trending" class="icon"><i class="fas fa-bolt"></i></div>
    </div>
    <div class="gallery_item_content">
      <div class="post-meta">
        <div class="meta-categories">
         <router-link :to="{ name: 'Features', params: { slug: datas.firstCategoria.slug} }">
            {{ datas.firstCategoria.titulo }}
         </router-link>
        </div>
        <div class="meta-date">
            <span>{{formatDate(datas.fecha)}}</span>
        </div>
      </div>
      <h4 class="title">
         <router-link :to="{ name: 'PostThree', params: { slug: datas.slug} }">
            {{ datas.titulo }}
         </router-link>
      </h4>
      <!-- <span v-if="counting">{{ counting }}</span> -->
    </div>
  </div>
   <!-- articulos más vistos-->
  <div
    v-else-if="counting"
    class="gallery_item gallery_item-style-2"
  >
    <div class="gallery_item_thumb">
      <router-link :to="{ name: 'PostThree', params: { slug: datas.slug} }">
         <img    
          v-if="datas.imagenURLSm"
          :src="datas.imagenURLSm" 
          alt="gallery"
        />
      </router-link>
      <div class="icon"><i class="fas fa-bolt"></i></div>
    </div>
    <div class="gallery_item_content">
      <div class="post-meta">
        <div class="meta-categories">
         <router-link :to="{ name: 'Features', params: { slug: datas.firstCategoria.slug} }">
            {{ datas.firstCategoria.titulo }}
         </router-link>
        </div>
        <div class="meta-date">
            <span>{{formatDate(datas.fecha)}}</span>
        </div>
      </div>
      <h4 class="title">
        <a href="#">{{ datas.titulo }}</a>
      </h4>
      <span>{{ count }}</span>
      <!-- <span>1</span> -->
    </div>
  </div>



  <!-- articulos -->
  <div
    v-else
    class="single__post d-lg-flex text-center text-lg-left"
  >
    <template v-if="datas">
      <div class="post-thumb mb-3 mb-lg-0">
         <router-link :to="{ name: 'PostThree', params: { slug: datas.slug} }">
            <img
               v-if="datas.imagenURLSm"
               :src="datas.imagenURLSm"
               alt="post"
            />
         </router-link>
      </div>
      <div class="post-content">
        <h4 class="title">
          <router-link :to="{ name: 'PostThree', params: { slug: datas.slug} }">
            {{ datas.titulo }}
          </router-link>
        </h4>
        
            <p v-html="datas.contenido.substring(0,74)+'...'"></p>
      </div>
    </template>
</div>


 
  
 


</template>

<script>
import moment from 'moment';


export default {
  props: {
    category: {
      type: Boolean,
    },
    trending: {
      type: Boolean,
    },
    sports: { type: Boolean, default: false },
    datas: {
      type: Object,
    },
    counting: {
      type: Boolean,
    },
    countingtwo: {
      type: Boolean,
    },
    count: {
      type: Number,
    },
  },

  setup() {
    const formatDate = (item) =>{
			moment.locale('es')
			let date = moment(item).format("MMMM D, YYYY");
			return date
		}
    return {
      formatDate,

    }
  }

};
</script>

<style></style>
