<template>
  <div class="most-share-post-item">
    <div class="post-meta">
      <div class="meta-categories">
        <router-link :to="{ name: 'Features', params: { slug: datas.firstCategoria.slug} }">
            {{ datas.firstCategoria.titulo }}
        
        </router-link>
      </div>
      <div class="meta-date">
        <span>{{formatDate(datas.fecha) }}</span>
      </div>
    </div>
    <h3 class="title">
      <router-link :to="{ name: 'PostThree', params: { slug: datas.slug} }">
        {{ datas.titulo }}
      </router-link>
    </h3>
    <!-- <ul>
      <li><i class="fab fa-twitter"></i> 2.2K</li>
      <li><i class="fab fa-facebook-f"></i> 3.5K</li>
    </ul> -->
    <div class="count">
      <router-link :to="{ name: 'PostThree', params: { slug: datas.slug} }">
        <span>{{ count }}</span>
      </router-link>  
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    datas: {
      type: Object,
    },
    count: {
      type: Number,
    },
  },

  setup() {
    const formatDate = (item) =>{
			moment.locale('es')
			let date = moment(item).format("MMMM D, YYYY");
			return date
		}
    return {
      formatDate,

    }
  }

};
</script>

<style></style>
