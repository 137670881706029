<template>
  <div
    :class="[
      'newspark-header-main-menu',
      darkClass && 'menuItem' in darkClass ? darkClass.menuItem : '',
    ]"
  >
    <ul v-if="nav_items">
      <li v-for="(item, index) in nav_items" :key="index">
        <router-link class="btn_menu " :to="`${item.link}`">
            <span v-if="item.linkText == 'INICIO'" :class="vista == '/' ? 'menu_active' : ''" >
                {{item.linkText}}
            </span>
            <span v-else-if="item.linkText == 'NOTICIAS'" :class="vista == '/noticias' ? 'menu_active' : ''" >
                {{item.linkText}}
            </span>
            <span v-else-if="item.linkText == 'CATEGORÍAS'" :class="vista == '/categorias' ? 'menu_active' : ''" >
                {{item.linkText}}
            </span>
        </router-link>
        <!-- <ul v-if="item.child" class="sub-menu">
          <li v-for="(lvlTwo, index) in item.submenu" :key="index">
            <router-link :to="`${lvlTwo.link}`">
              {{ lvlTwo.linkText }}
              <i v-if="lvlTwo.child" class="fal fa-angle-down"></i
            ></router-link>
            <ul v-if="lvlTwo.child" class="sub-menu">
              <li v-for="(lvlThree, index) in lvlTwo.third_menu" :key="index">
                <router-link :to="`${lvlThree.link}`">{{
                  lvlThree.linkText
                }}</router-link>
              </li>
            </ul>
          </li>
        </ul> -->
      </li>

      <li v-for="(item, index) in menuDepartamentosDest" :key="index.id">
        <router-link class="btn_menu" :to="{ name: 'Sports', params: { slug: item.slug} }">
            <span :class="vista == '/departamentos/'+item.nombre.toLowerCase()  ? 'menu_active' : ''" >
                {{item.nombre}}
            </span>
        </router-link>
      </li>

     
      <li v-if="menuDepartamentos">
        <span class="departamentos" >
          Provincias
        </span>
        <i class="fal fa-angle-down"></i>
         <ul v-if="menuDepartamentos" class="sub-menu">
          <li v-for="(lvlTwo, index) in menuDepartamentos" :key="index.id">
            <router-link :to="{ name: 'Sports', params: { slug: lvlTwo.slug} }">
              <!-- {{ lvlTwo.nombre }} -->
                <span :class="vista == '/departamentos/'+lvlTwo.nombre.toLowerCase()  ? 'menu_active' : ''" >
                    {{lvlTwo.nombre}}
                </span>
              
             </router-link>
          </li>
        </ul>
      </li>
    </ul>


     

  </div>
</template>

<script>
import navItems from "../../Data/NavItems.json";
export default {

 

  props: {
    darkClass: {
      type: Object,
    },
    menuDepartamentosDest: {
        type: Array,
        default: () => [],
    },
    menuDepartamentos: {
        type: Array,
        default: () => [],
    },
    
  },
  data: () => ({
    nav_items: navItems.data, // when you have api then delete json file (dir:components/data/) and add your data this variable
    vista: null,
  }),

    
  created(){
      this.vista = this.$route.path;
      console.log("menu",this.vista);
  },

  watch: {
    '$route.path': {
        handler: function() {
            console.log("menu",this.$route.path);
            this.vista = this.$route.path;
            console.log("menu",this.vista);
        },
        // deep: true,
        // immediate: true
    }
  },
};
</script>

<style>
.newspark-header-main-menu.header-two ul > li > a {
  color: #ffffff;
}
.newspark-header-main-menu.header-three-nav ul > li > a {
  color: white;
}
.header-three-nav {
  margin-left: 35px;
}
</style>
