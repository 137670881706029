import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);
export const store = new Vuex.Store({
    state: {
        videoPopup:false,
        loader: true,
    },
    mutations: {
        SET_VALUE_VIDEO:(state, payload)=> {
            console.log(payload)
            state.videoPopup = payload
            
        },

        SET_RELOAD_LOADER(state, value){
            state.loader = value;
            console.log("SET_RELOAD_LOADER",state.loader);
        },
    },
    actions: {
        toggleVideo({state,commit }) {
            commit('SET_VALUE_VIDEO',!state.videoPopup)
        },
        hideLoader({commit},value) {
            console.log("hideLoader");
            commit('SET_RELOAD_LOADER', value)
        }
    }

})